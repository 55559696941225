((MARY, $) => {
	// MVスライダー
	const setMVSlider = () => {
		$('.index-main-slider').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 800,
			arrows: true,
			appendArrows: $('.slick-nav'),
			prevArrow: '<button class="slick-prev"><i class="icon icon-arrow_left_bold"></button>',
			nextArrow: '<button class="slick-next"><i class="icon icon-arrow_right_bold"></button>',
			dots: true,
			appendDots: $('.slick-nav'),
			pauseOnFocus: true,
			pauseOnHover: false,
			infinite: true
		});
		$('.index-main .slick-pause').on('click', () => {
			$('.index-main-slider').slick('slickPause');
			$('.index-main .slick-pause').addClass('is-hidden');
			$('.index-main .slick-play').removeClass('is-hidden');
		});
		$('.index-main .slick-play').on('click', () => {
			$('.index-main-slider').slick('slickPlay');
			$('.index-main .slick-play').addClass('is-hidden');
			$('.index-main .slick-pause').removeClass('is-hidden');
		});
	};

	// Topics matchHeight ※PCのみ
	const adjustTopics = () => {
		$('.index-topics-column-text').matchHeight();
	};

	// Instagram Graph API
	const setInstagram = () => {
		const maxNumber = 6;
		const instaBusinessId = '17841403855899253';
		const accessToken =
			'EAAJosYimkMABOxrgvF7OoVJlDDXUIZAf8MnIeIBW8ZBJOXlhby0t5Q9DJcQOK6IUwGmrB4glyeGYTluZCTyKZAS3faBRKZAJIskVUoPZB0axTgWbhMUmPZArXF8EpZAMVR1OkLjSE9K6Ym0Loih2dfr6vE0kUGSQZCLWkd39jPwjU8GPI3bJY5o5x6DGIUvzdA8vd87wwCQJA';

		const instaJsonPath = `https://graph.facebook.com/v16.0/${instaBusinessId}` +
			`?fields=name%2Cmedia.limit(${maxNumber})%7Bmedia_url%2Cthumbnail_url%2Cpermalink%7D&access_token=${accessToken}`;
		console.log(instaJsonPath);
		fetch(instaJsonPath).then(response => response.json()).then(jsonData => {
			let appendHTML = '';
			jsonData.media.data.forEach(ele => {
				appendHTML += `<li><a href="${ele.permalink}" target="_blank" rel="noopener"><figure>`;
				if (ele.thumbnail_url) {
					appendHTML += `<img src="${ele.thumbnail_url}">`;
				} else {
					appendHTML += `<img src="${ele.media_url}">`;
				}
				appendHTML += '</figure></a></li>';
			});
			$('.index-instagram-list').append(appendHTML);
		});
	};

	setMVSlider();
	adjustTopics();
	setInstagram();

})(window.MARY, window.jQuery);
